import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Upload: any;
};

export type IAuthResponse = {
  refreshToken: Scalars['String'];
  token: Scalars['String'];
  user: IUser;
};

export type ICertificate = {
  createdAt: Scalars['Date'];
  exam: IExam;
  id: Scalars['Int'];
  protocolDate?: Maybe<Scalars['Date']>;
  protocolNumber?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Date'];
  user: IUser;
};

export type ICertificatesResponse = {
  list: Array<ICertificate>;
  total: Scalars['Int'];
};

export type ICreateCardPaymentResponse = {
  payment?: Maybe<IUserPayment>;
  paymentUrl: Scalars['String'];
};

export type ICreateExamRequestInput = {
  lang: ILang;
};

export type IExam = {
  createdAt: Scalars['Date'];
  duration?: Maybe<Scalars['Int']>;
  examQuestions?: Maybe<Array<IExamQuestion>>;
  finishedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  lang: ILang;
  scheduledFor?: Maybe<Scalars['Date']>;
  startedAt?: Maybe<Scalars['Date']>;
  status: IExamStatus;
  type: IExamType;
  updatedAt: Scalars['Date'];
  user: IUser;
};

export type IExamQuestion = {
  answer?: Maybe<IQuestionAnswer>;
  createdAt: Scalars['Date'];
  exam: IExam;
  id: Scalars['Int'];
  isCorrect?: Maybe<Scalars['Boolean']>;
  question: IQuestion;
  updatedAt: Scalars['Date'];
};

export type IExamResponse = {
  exam?: Maybe<IExam>;
  results?: Maybe<IExamResults>;
};

export type IExamResults = {
  correct: Scalars['Int'];
  unanswered: Scalars['Int'];
  wrong: Scalars['Int'];
};

export enum IExamStatus {
  CANCEL = 'cancel',
  FAIL = 'fail',
  IN_PROGRESS = 'in_progress',
  NEW = 'new',
  PENDING = 'pending',
  SUCCESS = 'success'
}

export enum IExamType {
  OFFLINE = 'offline',
  ONLINE = 'online'
}

export type IExamsFilter = {
  period?: InputMaybe<IPeriodInput>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<IExamStatus>;
};

export type IExamsResponse = {
  list: Array<IExam>;
  total: Scalars['Int'];
};

export type IFile = {
  createdAt: Scalars['Date'];
  extension: Scalars['String'];
  id: Scalars['Int'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  size: Scalars['Int'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export enum IFileType {
  FILE = 'file',
  IMAGE = 'image'
}

export enum ILang {
  KK = 'kk',
  RU = 'ru'
}

export type ILesson = {
  content: Scalars['String'];
  createdAt: Scalars['Date'];
  files?: Maybe<Array<IFile>>;
  id: Scalars['Int'];
  lang: ILang;
  shortDescription: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type ILessonInput = {
  content: Scalars['String'];
  fileIds: Array<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lang: ILang;
  shortDescription: Scalars['String'];
  title: Scalars['String'];
};

export type ILessonsFilter = {
  lang: ILang;
};

export type ILessonsResponse = {
  list: Array<ILesson>;
  total: Scalars['Int'];
};

export type IMutation = {
  answerExamQuestion: Scalars['Boolean'];
  assignExam: Scalars['Boolean'];
  cancelExam: Scalars['Boolean'];
  confirmEmail: IAuthResponse;
  createCardPayment: ICreateCardPaymentResponse;
  createCashPayment: IUserPayment;
  createCertificateByAdmin: Scalars['Boolean'];
  createExamRequest?: Maybe<IExam>;
  createQuestion: IQuestion;
  deleteLesson: Scalars['Boolean'];
  deleteQuestion: Scalars['Boolean'];
  exportUsersCsv: Scalars['String'];
  forgotPassword: Scalars['Boolean'];
  login: IAuthResponse;
  loginAdmin: IAuthResponse;
  payCardPayment: ICreateCardPaymentResponse;
  register: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  resolveCashPayment: Scalars['Boolean'];
  singleFileUpload: IFile;
  startExam: IExam;
  updateAdminData: IUser;
  updateProfileData: IUser;
  updateQuestion: IQuestion;
  updateUserData: IUser;
  updateUserDataByAdmin: IUser;
  updateUserProfileByAdmin: IUser;
  upsertLesson: ILesson;
};


export type IMutationAnswerExamQuestionArgs = {
  answerId: Scalars['Int'];
  examId: Scalars['Int'];
  examQuestionId: Scalars['Int'];
};


export type IMutationAssignExamArgs = {
  date: Scalars['Date'];
  id: Scalars['Int'];
};


export type IMutationCancelExamArgs = {
  id: Scalars['Int'];
};


export type IMutationConfirmEmailArgs = {
  token: Scalars['String'];
};


export type IMutationCreateCashPaymentArgs = {
  file: Scalars['Upload'];
  type: IFileType;
};


export type IMutationCreateCertificateByAdminArgs = {
  certificateDate: Scalars['Date'];
  userId: Scalars['Int'];
};


export type IMutationCreateExamRequestArgs = {
  input: ICreateExamRequestInput;
};


export type IMutationCreateQuestionArgs = {
  input: IQuestionInput;
};


export type IMutationDeleteLessonArgs = {
  id: Scalars['Int'];
};


export type IMutationDeleteQuestionArgs = {
  id: Scalars['Int'];
};


export type IMutationExportUsersCsvArgs = {
  filter?: InputMaybe<IUsersFilter>;
};


export type IMutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type IMutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type IMutationLoginAdminArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type IMutationPayCardPaymentArgs = {
  paymentId: Scalars['Int'];
};


export type IMutationRegisterArgs = {
  input: IRegisterInput;
};


export type IMutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type IMutationResolveCashPaymentArgs = {
  paymentId: Scalars['Int'];
  status: IPaymentStatus;
};


export type IMutationSingleFileUploadArgs = {
  file: Scalars['Upload'];
  type: IFileType;
};


export type IMutationStartExamArgs = {
  id: Scalars['Int'];
};


export type IMutationUpdateAdminDataArgs = {
  input: IUpdateAdminDataInput;
};


export type IMutationUpdateProfileDataArgs = {
  input: IUpdateProfileDataInput;
};


export type IMutationUpdateQuestionArgs = {
  input: IQuestionInput;
};


export type IMutationUpdateUserDataArgs = {
  input: IUpdateUserDataInput;
};


export type IMutationUpdateUserDataByAdminArgs = {
  input: IUpdateUserDataByAdminInput;
  userId: Scalars['Int'];
};


export type IMutationUpdateUserProfileByAdminArgs = {
  input: IUpdateProfileDataInput;
  userId: Scalars['Int'];
};


export type IMutationUpsertLessonArgs = {
  input?: InputMaybe<ILessonInput>;
};

export type IPaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export enum IPaymentMethod {
  CARD = 'card',
  CASH = 'cash'
}

export enum IPaymentStatus {
  FAILED = 'failed',
  NEW = 'new',
  PAID = 'paid',
  PENDING = 'pending'
}

export type IPeriodInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type IProfileAddress = {
  apartment?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  createdAt: Scalars['Date'];
  district: Scalars['String'];
  house: Scalars['String'];
  street: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type IQuery = {
  certificate?: Maybe<ICertificate>;
  certificates: ICertificatesResponse;
  exam?: Maybe<IExamResponse>;
  exams: IExamsResponse;
  examsForCalendar: IExamsResponse;
  lesson?: Maybe<ILesson>;
  lessons: ILessonsResponse;
  me: IUser;
  myCertificate?: Maybe<ICertificate>;
  myCertificates: ICertificatesResponse;
  myExam?: Maybe<IExamResponse>;
  myExams: IExamsResponse;
  question?: Maybe<IQuestion>;
  questions: IQuestionsResponse;
  test?: Maybe<Scalars['String']>;
  userByAdmin: IUser;
  userPayments: IUserPaymentsResponse;
  userPaymentsByAdmin: IUserPaymentsResponse;
  users: IUsersResponse;
};


export type IQueryCertificateArgs = {
  id: Scalars['Int'];
};


export type IQueryCertificatesArgs = {
  userId?: InputMaybe<Scalars['Int']>;
};


export type IQueryExamArgs = {
  id: Scalars['Int'];
};


export type IQueryExamsArgs = {
  filter?: InputMaybe<IExamsFilter>;
  pagination?: InputMaybe<IPaginationInput>;
};


export type IQueryExamsForCalendarArgs = {
  period: IPeriodInput;
};


export type IQueryLessonArgs = {
  id: Scalars['Int'];
};


export type IQueryLessonsArgs = {
  filter?: InputMaybe<ILessonsFilter>;
  pagination?: InputMaybe<IPaginationInput>;
};


export type IQueryMyCertificateArgs = {
  id: Scalars['Int'];
};


export type IQueryMyExamArgs = {
  id: Scalars['Int'];
};


export type IQueryMyExamsArgs = {
  filter?: InputMaybe<IExamsFilter>;
  pagination?: InputMaybe<IPaginationInput>;
};


export type IQueryQuestionArgs = {
  id: Scalars['Int'];
};


export type IQueryQuestionsArgs = {
  filter?: InputMaybe<IQuestionsFilter>;
  pagination?: InputMaybe<IPaginationInput>;
};


export type IQueryUserByAdminArgs = {
  id: Scalars['Int'];
};


export type IQueryUserPaymentsByAdminArgs = {
  userId: Scalars['Int'];
};


export type IQueryUsersArgs = {
  filter?: InputMaybe<IUsersFilter>;
  pagination?: InputMaybe<IPaginationInput>;
};

export type IQuestion = {
  answers: Array<IQuestionAnswer>;
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  lang: ILang;
  question: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type IQuestionAnswer = {
  answer: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  isCorrect?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['Date'];
};

export type IQuestionAnswerInput = {
  answer: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  isCorrect: Scalars['Boolean'];
};

export type IQuestionInput = {
  answers: Array<IQuestionAnswerInput>;
  id?: InputMaybe<Scalars['Int']>;
  lang: ILang;
  question: Scalars['String'];
};

export type IQuestionsFilter = {
  lang: ILang;
};

export type IQuestionsResponse = {
  list: Array<IQuestion>;
  total: Scalars['Int'];
};

export type IRegisterInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone: Scalars['String'];
};

export type IUpdateAdminDataInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type IUpdateProfileAddressInput = {
  apartment?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  district: Scalars['String'];
  house: Scalars['String'];
  street: Scalars['String'];
};

export type IUpdateProfileDataInput = {
  address: IUpdateProfileAddressInput;
  citizenship: Scalars['String'];
  dateOfBirth: Scalars['Date'];
  idCardImage1Id: Scalars['Int'];
  idCardImage2Id: Scalars['Int'];
  idCardNumber: Scalars['String'];
  idIssuedAt: Scalars['Date'];
  iin: Scalars['String'];
  issuer: Scalars['String'];
  stateFeeReceiptFileId?: InputMaybe<Scalars['Int']>;
};

export type IUpdateUserDataByAdminInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type IUpdateUserDataInput = {
  password?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type IUser = {
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  profile?: Maybe<IUserProfile>;
  status: IUserStatus;
  updatedAt: Scalars['Date'];
};

export type IUserPayment = {
  amount: Scalars['Float'];
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  method: IPaymentMethod;
  receiptImage?: Maybe<IFile>;
  status: IPaymentStatus;
  updatedAt: Scalars['Date'];
  user: IUser;
};

export type IUserPaymentsResponse = {
  list: Array<Maybe<IUserPayment>>;
  total: Scalars['Int'];
};

export type IUserProfile = {
  address?: Maybe<IProfileAddress>;
  citizenship?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  idCardImage1: IFile;
  idCardImage2: IFile;
  idCardNumber: Scalars['String'];
  idIssuedAt: Scalars['Date'];
  iin: Scalars['String'];
  issuer: Scalars['String'];
  stateFeeReceiptFile?: Maybe<IFile>;
};

export enum IUserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  NEW = 'new',
  PENDING = 'pending'
}

export type IUsersFilter = {
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<IUserStatus>;
};

export type IUsersResponse = {
  list: Array<IUser>;
  total: Scalars['Int'];
};

export type IMeQueryVariables = Exact<{ [key: string]: never; }>;


export type IMeQuery = { me: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null } };

export type IRegisterMutationVariables = Exact<{
  input: IRegisterInput;
}>;


export type IRegisterMutation = { register: boolean };

export type IConfirmEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type IConfirmEmailMutation = { confirmEmail: { token: string, refreshToken: string, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null } } };

export type ILoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type ILoginMutation = { login: { token: string, refreshToken: string, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null } } };

export type ILoginAdminMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type ILoginAdminMutation = { loginAdmin: { token: string, refreshToken: string, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null } } };

export type IForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type IForgotPasswordMutation = { forgotPassword: boolean };

export type IResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type IResetPasswordMutation = { resetPassword: boolean };

export type ICertificateFragmentFragment = { id: number, protocolNumber?: number | null, protocolDate?: any | null, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null }, exam: { id: number, type: IExamType } };

export type ICertificateQueryVariables = Exact<{
  certificateId: Scalars['Int'];
}>;


export type ICertificateQuery = { certificate?: { id: number, protocolNumber?: number | null, protocolDate?: any | null, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null }, exam: { id: number, type: IExamType } } | null };

export type ICertificatesQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['Int']>;
}>;


export type ICertificatesQuery = { certificates: { total: number, list: Array<{ id: number, protocolNumber?: number | null, protocolDate?: any | null, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null }, exam: { id: number, type: IExamType } }> } };

export type IMyCertificateQueryVariables = Exact<{
  myCertificateId: Scalars['Int'];
}>;


export type IMyCertificateQuery = { myCertificate?: { id: number, protocolNumber?: number | null, protocolDate?: any | null, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null }, exam: { id: number, type: IExamType } } | null };

export type IMyCertificatesQueryVariables = Exact<{ [key: string]: never; }>;


export type IMyCertificatesQuery = { myCertificates: { total: number, list: Array<{ id: number, protocolNumber?: number | null, protocolDate?: any | null, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null }, exam: { id: number, type: IExamType } }> } };

export type ICreateCertificateByAdminMutationVariables = Exact<{
  userId: Scalars['Int'];
  certificateDate: Scalars['Date'];
}>;


export type ICreateCertificateByAdminMutation = { createCertificateByAdmin: boolean };

export type IExamQuestionFragmentFragment = { id: number, isCorrect?: boolean | null, createdAt: any, updatedAt: any, question: { id: number, lang: ILang, question: string, createdAt: any, updatedAt: any, answers: Array<{ id: number, answer: string, isCorrect?: boolean | null }> }, answer?: { id: number, answer: string } | null };

export type IAnswerExamQuestionMutationVariables = Exact<{
  examId: Scalars['Int'];
  examQuestionId: Scalars['Int'];
  answerId: Scalars['Int'];
}>;


export type IAnswerExamQuestionMutation = { answerExamQuestion: boolean };

export type IExamFragmentFragment = { id: number, lang: ILang, status: IExamStatus, scheduledFor?: any | null, startedAt?: any | null, finishedAt?: any | null, duration?: number | null, type: IExamType, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any }, examQuestions?: Array<{ id: number, isCorrect?: boolean | null, createdAt: any, updatedAt: any, question: { id: number, lang: ILang, question: string, createdAt: any, updatedAt: any, answers: Array<{ id: number, answer: string, isCorrect?: boolean | null }> }, answer?: { id: number, answer: string } | null }> | null };

export type IExamResultsFragment = { correct: number, wrong: number, unanswered: number };

export type IExamQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IExamQuery = { exam?: { exam?: { id: number, lang: ILang, status: IExamStatus, scheduledFor?: any | null, startedAt?: any | null, finishedAt?: any | null, duration?: number | null, type: IExamType, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any }, examQuestions?: Array<{ id: number, isCorrect?: boolean | null, createdAt: any, updatedAt: any, question: { id: number, lang: ILang, question: string, createdAt: any, updatedAt: any, answers: Array<{ id: number, answer: string, isCorrect?: boolean | null }> }, answer?: { id: number, answer: string } | null }> | null } | null, results?: { correct: number, wrong: number, unanswered: number } | null } | null };

export type IExamsQueryVariables = Exact<{
  filter?: InputMaybe<IExamsFilter>;
  pagination?: InputMaybe<IPaginationInput>;
}>;


export type IExamsQuery = { exams: { total: number, list: Array<{ id: number, lang: ILang, status: IExamStatus, scheduledFor?: any | null, startedAt?: any | null, finishedAt?: any | null, duration?: number | null, type: IExamType, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any }, examQuestions?: Array<{ id: number, isCorrect?: boolean | null, createdAt: any, updatedAt: any, question: { id: number, lang: ILang, question: string, createdAt: any, updatedAt: any, answers: Array<{ id: number, answer: string, isCorrect?: boolean | null }> }, answer?: { id: number, answer: string } | null }> | null }> } };

export type IExamsForCalendarQueryVariables = Exact<{
  period: IPeriodInput;
}>;


export type IExamsForCalendarQuery = { examsForCalendar: { total: number, list: Array<{ id: number, lang: ILang, status: IExamStatus, scheduledFor?: any | null, startedAt?: any | null, finishedAt?: any | null, duration?: number | null, type: IExamType, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any }, examQuestions?: Array<{ id: number, isCorrect?: boolean | null, createdAt: any, updatedAt: any, question: { id: number, lang: ILang, question: string, createdAt: any, updatedAt: any, answers: Array<{ id: number, answer: string, isCorrect?: boolean | null }> }, answer?: { id: number, answer: string } | null }> | null }> } };

export type IMyExamQueryVariables = Exact<{
  myExamId: Scalars['Int'];
}>;


export type IMyExamQuery = { myExam?: { exam?: { id: number, lang: ILang, status: IExamStatus, scheduledFor?: any | null, startedAt?: any | null, finishedAt?: any | null, duration?: number | null, type: IExamType, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any }, examQuestions?: Array<{ id: number, isCorrect?: boolean | null, createdAt: any, updatedAt: any, question: { id: number, lang: ILang, question: string, createdAt: any, updatedAt: any, answers: Array<{ id: number, answer: string, isCorrect?: boolean | null }> }, answer?: { id: number, answer: string } | null }> | null } | null, results?: { correct: number, wrong: number, unanswered: number } | null } | null };

export type IMyExamsQueryVariables = Exact<{
  filter?: InputMaybe<IExamsFilter>;
  pagination?: InputMaybe<IPaginationInput>;
}>;


export type IMyExamsQuery = { myExams: { total: number, list: Array<{ id: number, lang: ILang, status: IExamStatus, scheduledFor?: any | null, startedAt?: any | null, finishedAt?: any | null, duration?: number | null, type: IExamType, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any }, examQuestions?: Array<{ id: number, isCorrect?: boolean | null, createdAt: any, updatedAt: any, question: { id: number, lang: ILang, question: string, createdAt: any, updatedAt: any, answers: Array<{ id: number, answer: string, isCorrect?: boolean | null }> }, answer?: { id: number, answer: string } | null }> | null }> } };

export type ICreateExamRequestMutationVariables = Exact<{
  input: ICreateExamRequestInput;
}>;


export type ICreateExamRequestMutation = { createExamRequest?: { id: number, lang: ILang, status: IExamStatus, scheduledFor?: any | null, startedAt?: any | null, finishedAt?: any | null, duration?: number | null, type: IExamType, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any }, examQuestions?: Array<{ id: number, isCorrect?: boolean | null, createdAt: any, updatedAt: any, question: { id: number, lang: ILang, question: string, createdAt: any, updatedAt: any, answers: Array<{ id: number, answer: string, isCorrect?: boolean | null }> }, answer?: { id: number, answer: string } | null }> | null } | null };

export type IAssignExamMutationVariables = Exact<{
  id: Scalars['Int'];
  date: Scalars['Date'];
}>;


export type IAssignExamMutation = { assignExam: boolean };

export type IStartExamMutationVariables = Exact<{
  startExamId: Scalars['Int'];
}>;


export type IStartExamMutation = { startExam: { id: number, lang: ILang, status: IExamStatus, scheduledFor?: any | null, startedAt?: any | null, finishedAt?: any | null, duration?: number | null, type: IExamType, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any }, examQuestions?: Array<{ id: number, isCorrect?: boolean | null, createdAt: any, updatedAt: any, question: { id: number, lang: ILang, question: string, createdAt: any, updatedAt: any, answers: Array<{ id: number, answer: string, isCorrect?: boolean | null }> }, answer?: { id: number, answer: string } | null }> | null } };

export type ICancelExamMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ICancelExamMutation = { cancelExam: boolean };

export type IFileFragmentFragment = { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any };

export type ISingleFileUploadMutationVariables = Exact<{
  file: Scalars['Upload'];
  type: IFileType;
}>;


export type ISingleFileUploadMutation = { singleFileUpload: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } };

export type ILessonFragmentFragment = { id: number, lang: ILang, title: string, shortDescription: string, content: string, createdAt: any, updatedAt: any, files?: Array<{ id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }> | null };

export type ILessonQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ILessonQuery = { lesson?: { id: number, lang: ILang, title: string, shortDescription: string, content: string, createdAt: any, updatedAt: any, files?: Array<{ id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }> | null } | null };

export type ILessonsQueryVariables = Exact<{
  filter?: InputMaybe<ILessonsFilter>;
  pagination?: InputMaybe<IPaginationInput>;
}>;


export type ILessonsQuery = { lessons: { total: number, list: Array<{ id: number, lang: ILang, title: string, shortDescription: string, content: string, createdAt: any, updatedAt: any, files?: Array<{ id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }> | null }> } };

export type IUpsertLessonMutationVariables = Exact<{
  input?: InputMaybe<ILessonInput>;
}>;


export type IUpsertLessonMutation = { upsertLesson: { id: number, lang: ILang, title: string, shortDescription: string, content: string, createdAt: any, updatedAt: any, files?: Array<{ id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }> | null } };

export type IDeleteLessonMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IDeleteLessonMutation = { deleteLesson: boolean };

export type IQuestionAnswerFragmentFragment = { id: number, answer: string, isCorrect?: boolean | null, createdAt: any, updatedAt: any };

export type IQuestionFragmentFragment = { id: number, lang: ILang, question: string, createdAt: any, updatedAt: any, answers: Array<{ id: number, answer: string, isCorrect?: boolean | null, createdAt: any, updatedAt: any }> };

export type IQuestionQueryVariables = Exact<{
  questionId: Scalars['Int'];
}>;


export type IQuestionQuery = { question?: { id: number, lang: ILang, question: string, createdAt: any, updatedAt: any, answers: Array<{ id: number, answer: string, isCorrect?: boolean | null, createdAt: any, updatedAt: any }> } | null };

export type IQuestionsQueryVariables = Exact<{
  filter?: InputMaybe<IQuestionsFilter>;
  pagination?: InputMaybe<IPaginationInput>;
}>;


export type IQuestionsQuery = { questions: { total: number, list: Array<{ id: number, lang: ILang, question: string, createdAt: any, updatedAt: any, answers: Array<{ id: number, answer: string, isCorrect?: boolean | null, createdAt: any, updatedAt: any }> }> } };

export type ICreateQuestionMutationVariables = Exact<{
  input: IQuestionInput;
}>;


export type ICreateQuestionMutation = { createQuestion: { id: number, lang: ILang, question: string, createdAt: any, updatedAt: any, answers: Array<{ id: number, answer: string, isCorrect?: boolean | null, createdAt: any, updatedAt: any }> } };

export type IUpdateQuestionMutationVariables = Exact<{
  input: IQuestionInput;
}>;


export type IUpdateQuestionMutation = { updateQuestion: { id: number, lang: ILang, question: string, createdAt: any, updatedAt: any, answers: Array<{ id: number, answer: string, isCorrect?: boolean | null, createdAt: any, updatedAt: any }> } };

export type IDeleteQuestionMutationVariables = Exact<{
  deleteQuestionId: Scalars['Int'];
}>;


export type IDeleteQuestionMutation = { deleteQuestion: boolean };

export type IUserPaymentFragmentFragment = { id: number, amount: number, status: IPaymentStatus, method: IPaymentMethod, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any }, receiptImage?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null };

export type IUserPaymentsQueryVariables = Exact<{ [key: string]: never; }>;


export type IUserPaymentsQuery = { userPayments: { total: number, list: Array<{ id: number, amount: number, status: IPaymentStatus, method: IPaymentMethod, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any }, receiptImage?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null } | null> } };

export type IUserPaymentsByAdminQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type IUserPaymentsByAdminQuery = { userPaymentsByAdmin: { total: number, list: Array<{ id: number, amount: number, status: IPaymentStatus, method: IPaymentMethod, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any }, receiptImage?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null } | null> } };

export type ICreateCashPaymentMutationVariables = Exact<{
  file: Scalars['Upload'];
  type: IFileType;
}>;


export type ICreateCashPaymentMutation = { createCashPayment: { id: number, amount: number, status: IPaymentStatus, method: IPaymentMethod, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any }, receiptImage?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null } };

export type IResolveCashPaymentMutationVariables = Exact<{
  paymentId: Scalars['Int'];
  status: IPaymentStatus;
}>;


export type IResolveCashPaymentMutation = { resolveCashPayment: boolean };

export type ICreateCardPaymentMutationVariables = Exact<{ [key: string]: never; }>;


export type ICreateCardPaymentMutation = { createCardPayment: { paymentUrl: string, payment?: { id: number, amount: number, status: IPaymentStatus, method: IPaymentMethod, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any }, receiptImage?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null } | null } };

export type IPayCardPaymentMutationVariables = Exact<{
  paymentId: Scalars['Int'];
}>;


export type IPayCardPaymentMutation = { payCardPayment: { paymentUrl: string, payment?: { id: number, amount: number, status: IPaymentStatus, method: IPaymentMethod, createdAt: any, updatedAt: any, user: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any }, receiptImage?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null } | null } };

export type IUserProfileFragmentFragment = { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null };

export type IUserFragmentFragment = { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null };

export type IUsersQueryVariables = Exact<{
  filter?: InputMaybe<IUsersFilter>;
  pagination?: InputMaybe<IPaginationInput>;
}>;


export type IUsersQuery = { users: { total: number, list: Array<{ id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null }> } };

export type IUserByAdminQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IUserByAdminQuery = { userByAdmin: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null } };

export type IUpdateProfileDataMutationVariables = Exact<{
  input: IUpdateProfileDataInput;
}>;


export type IUpdateProfileDataMutation = { updateProfileData: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null } };

export type IUpdateUserProfileByAdminMutationVariables = Exact<{
  userId: Scalars['Int'];
  input: IUpdateProfileDataInput;
}>;


export type IUpdateUserProfileByAdminMutation = { updateUserProfileByAdmin: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null } };

export type IUpdateUserDataMutationVariables = Exact<{
  input: IUpdateUserDataInput;
}>;


export type IUpdateUserDataMutation = { updateUserData: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null } };

export type IUpdateUserDataByAdminMutationVariables = Exact<{
  userId: Scalars['Int'];
  input: IUpdateUserDataByAdminInput;
}>;


export type IUpdateUserDataByAdminMutation = { updateUserDataByAdmin: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null } };

export type IUpdateAdminDataMutationVariables = Exact<{
  input: IUpdateAdminDataInput;
}>;


export type IUpdateAdminDataMutation = { updateAdminData: { id: number, firstName: string, lastName: string, middleName?: string | null, fullName: string, email: string, phone: string, status: IUserStatus, createdAt: any, updatedAt: any, profile?: { idCardNumber: string, idIssuedAt: any, issuer: string, iin: string, dateOfBirth?: any | null, citizenship?: string | null, idCardImage1: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, idCardImage2: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any }, stateFeeReceiptFile?: { id: number, type: string, name: string, path?: string | null, extension: string, size: number, mimeType: string, url: string, createdAt: any } | null, address?: { city: string, district: string, street: string, house: string, apartment?: string | null } | null } | null } };

export type IExportUsersCsvMutationVariables = Exact<{
  filter?: InputMaybe<IUsersFilter>;
}>;


export type IExportUsersCsvMutation = { exportUsersCsv: string };

export const FileFragmentFragmentDoc = gql`
    fragment FileFragment on File {
  id
  type
  name
  path
  extension
  size
  mimeType
  url
  createdAt
}
    `;
export const UserProfileFragmentFragmentDoc = gql`
    fragment UserProfileFragment on UserProfile {
  idCardNumber
  idIssuedAt
  issuer
  iin
  idCardImage1 {
    ...FileFragment
  }
  idCardImage2 {
    ...FileFragment
  }
  stateFeeReceiptFile {
    ...FileFragment
  }
  address {
    city
    district
    street
    house
    apartment
  }
  dateOfBirth
  citizenship
}
    ${FileFragmentFragmentDoc}`;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  firstName
  lastName
  middleName
  fullName
  email
  phone
  profile {
    ...UserProfileFragment
  }
  status
  createdAt
  updatedAt
}
    ${UserProfileFragmentFragmentDoc}`;
export const CertificateFragmentFragmentDoc = gql`
    fragment CertificateFragment on Certificate {
  id
  user {
    ...UserFragment
  }
  exam {
    id
    type
  }
  protocolNumber
  protocolDate
  createdAt
  updatedAt
}
    ${UserFragmentFragmentDoc}`;
export const ExamQuestionFragmentFragmentDoc = gql`
    fragment ExamQuestionFragment on ExamQuestion {
  id
  question {
    id
    lang
    question
    answers {
      id
      answer
      isCorrect
    }
    createdAt
    updatedAt
  }
  answer {
    id
    answer
  }
  isCorrect
  createdAt
  updatedAt
}
    `;
export const ExamFragmentFragmentDoc = gql`
    fragment ExamFragment on Exam {
  id
  user {
    id
    firstName
    lastName
    middleName
    fullName
    email
    phone
    status
    createdAt
    updatedAt
  }
  lang
  status
  scheduledFor
  startedAt
  finishedAt
  duration
  examQuestions {
    ...ExamQuestionFragment
  }
  type
  createdAt
  updatedAt
}
    ${ExamQuestionFragmentFragmentDoc}`;
export const ExamResultsFragmentDoc = gql`
    fragment ExamResults on ExamResults {
  correct
  wrong
  unanswered
}
    `;
export const LessonFragmentFragmentDoc = gql`
    fragment LessonFragment on Lesson {
  id
  lang
  title
  shortDescription
  content
  files {
    ...FileFragment
  }
  createdAt
  updatedAt
}
    ${FileFragmentFragmentDoc}`;
export const QuestionAnswerFragmentFragmentDoc = gql`
    fragment QuestionAnswerFragment on QuestionAnswer {
  id
  answer
  isCorrect
  createdAt
  updatedAt
}
    `;
export const QuestionFragmentFragmentDoc = gql`
    fragment QuestionFragment on Question {
  id
  lang
  question
  answers {
    ...QuestionAnswerFragment
  }
  createdAt
  updatedAt
}
    ${QuestionAnswerFragmentFragmentDoc}`;
export const UserPaymentFragmentFragmentDoc = gql`
    fragment UserPaymentFragment on UserPayment {
  id
  user {
    id
    firstName
    lastName
    middleName
    fullName
    email
    phone
    status
    createdAt
    updatedAt
  }
  amount
  status
  method
  receiptImage {
    ...FileFragment
  }
  createdAt
  updatedAt
}
    ${FileFragmentFragmentDoc}`;
export const MeDocument = gql`
    query Me {
  me {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<IMeQuery, IMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMeQuery, IMeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMeQuery, IMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMeQuery, IMeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<IMeQuery, IMeQueryVariables>;
export function refetchMeQuery(variables?: IMeQueryVariables) {
      return { query: MeDocument, variables: variables }
    }
export const RegisterDocument = gql`
    mutation Register($input: RegisterInput!) {
  register(input: $input)
}
    `;
export type IRegisterMutationFn = Apollo.MutationFunction<IRegisterMutation, IRegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<IRegisterMutation, IRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRegisterMutation, IRegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<IRegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<IRegisterMutation, IRegisterMutationVariables>;
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($token: String!) {
  confirmEmail(token: $token) {
    token
    refreshToken
    user {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type IConfirmEmailMutationFn = Apollo.MutationFunction<IConfirmEmailMutation, IConfirmEmailMutationVariables>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<IConfirmEmailMutation, IConfirmEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IConfirmEmailMutation, IConfirmEmailMutationVariables>(ConfirmEmailDocument, options);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<IConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<IConfirmEmailMutation, IConfirmEmailMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
    refreshToken
    user {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type ILoginMutationFn = Apollo.MutationFunction<ILoginMutation, ILoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<ILoginMutation, ILoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ILoginMutation, ILoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<ILoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<ILoginMutation, ILoginMutationVariables>;
export const LoginAdminDocument = gql`
    mutation LoginAdmin($email: String!, $password: String!) {
  loginAdmin(email: $email, password: $password) {
    token
    refreshToken
    user {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type ILoginAdminMutationFn = Apollo.MutationFunction<ILoginAdminMutation, ILoginAdminMutationVariables>;

/**
 * __useLoginAdminMutation__
 *
 * To run a mutation, you first call `useLoginAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAdminMutation, { data, loading, error }] = useLoginAdminMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginAdminMutation(baseOptions?: Apollo.MutationHookOptions<ILoginAdminMutation, ILoginAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ILoginAdminMutation, ILoginAdminMutationVariables>(LoginAdminDocument, options);
      }
export type LoginAdminMutationHookResult = ReturnType<typeof useLoginAdminMutation>;
export type LoginAdminMutationResult = Apollo.MutationResult<ILoginAdminMutation>;
export type LoginAdminMutationOptions = Apollo.BaseMutationOptions<ILoginAdminMutation, ILoginAdminMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type IForgotPasswordMutationFn = Apollo.MutationFunction<IForgotPasswordMutation, IForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<IForgotPasswordMutation, IForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IForgotPasswordMutation, IForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<IForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<IForgotPasswordMutation, IForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $newPassword: String!) {
  resetPassword(token: $token, newPassword: $newPassword)
}
    `;
export type IResetPasswordMutationFn = Apollo.MutationFunction<IResetPasswordMutation, IResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<IResetPasswordMutation, IResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IResetPasswordMutation, IResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<IResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<IResetPasswordMutation, IResetPasswordMutationVariables>;
export const CertificateDocument = gql`
    query Certificate($certificateId: Int!) {
  certificate(id: $certificateId) {
    ...CertificateFragment
  }
}
    ${CertificateFragmentFragmentDoc}`;

/**
 * __useCertificateQuery__
 *
 * To run a query within a React component, call `useCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificateQuery({
 *   variables: {
 *      certificateId: // value for 'certificateId'
 *   },
 * });
 */
export function useCertificateQuery(baseOptions: Apollo.QueryHookOptions<ICertificateQuery, ICertificateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ICertificateQuery, ICertificateQueryVariables>(CertificateDocument, options);
      }
export function useCertificateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ICertificateQuery, ICertificateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ICertificateQuery, ICertificateQueryVariables>(CertificateDocument, options);
        }
export type CertificateQueryHookResult = ReturnType<typeof useCertificateQuery>;
export type CertificateLazyQueryHookResult = ReturnType<typeof useCertificateLazyQuery>;
export type CertificateQueryResult = Apollo.QueryResult<ICertificateQuery, ICertificateQueryVariables>;
export function refetchCertificateQuery(variables: ICertificateQueryVariables) {
      return { query: CertificateDocument, variables: variables }
    }
export const CertificatesDocument = gql`
    query Certificates($userId: Int) {
  certificates(userId: $userId) {
    total
    list {
      ...CertificateFragment
    }
  }
}
    ${CertificateFragmentFragmentDoc}`;

/**
 * __useCertificatesQuery__
 *
 * To run a query within a React component, call `useCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificatesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCertificatesQuery(baseOptions?: Apollo.QueryHookOptions<ICertificatesQuery, ICertificatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ICertificatesQuery, ICertificatesQueryVariables>(CertificatesDocument, options);
      }
export function useCertificatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ICertificatesQuery, ICertificatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ICertificatesQuery, ICertificatesQueryVariables>(CertificatesDocument, options);
        }
export type CertificatesQueryHookResult = ReturnType<typeof useCertificatesQuery>;
export type CertificatesLazyQueryHookResult = ReturnType<typeof useCertificatesLazyQuery>;
export type CertificatesQueryResult = Apollo.QueryResult<ICertificatesQuery, ICertificatesQueryVariables>;
export function refetchCertificatesQuery(variables?: ICertificatesQueryVariables) {
      return { query: CertificatesDocument, variables: variables }
    }
export const MyCertificateDocument = gql`
    query MyCertificate($myCertificateId: Int!) {
  myCertificate(id: $myCertificateId) {
    ...CertificateFragment
  }
}
    ${CertificateFragmentFragmentDoc}`;

/**
 * __useMyCertificateQuery__
 *
 * To run a query within a React component, call `useMyCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCertificateQuery({
 *   variables: {
 *      myCertificateId: // value for 'myCertificateId'
 *   },
 * });
 */
export function useMyCertificateQuery(baseOptions: Apollo.QueryHookOptions<IMyCertificateQuery, IMyCertificateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMyCertificateQuery, IMyCertificateQueryVariables>(MyCertificateDocument, options);
      }
export function useMyCertificateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMyCertificateQuery, IMyCertificateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMyCertificateQuery, IMyCertificateQueryVariables>(MyCertificateDocument, options);
        }
export type MyCertificateQueryHookResult = ReturnType<typeof useMyCertificateQuery>;
export type MyCertificateLazyQueryHookResult = ReturnType<typeof useMyCertificateLazyQuery>;
export type MyCertificateQueryResult = Apollo.QueryResult<IMyCertificateQuery, IMyCertificateQueryVariables>;
export function refetchMyCertificateQuery(variables: IMyCertificateQueryVariables) {
      return { query: MyCertificateDocument, variables: variables }
    }
export const MyCertificatesDocument = gql`
    query MyCertificates {
  myCertificates {
    total
    list {
      ...CertificateFragment
    }
  }
}
    ${CertificateFragmentFragmentDoc}`;

/**
 * __useMyCertificatesQuery__
 *
 * To run a query within a React component, call `useMyCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCertificatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCertificatesQuery(baseOptions?: Apollo.QueryHookOptions<IMyCertificatesQuery, IMyCertificatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMyCertificatesQuery, IMyCertificatesQueryVariables>(MyCertificatesDocument, options);
      }
export function useMyCertificatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMyCertificatesQuery, IMyCertificatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMyCertificatesQuery, IMyCertificatesQueryVariables>(MyCertificatesDocument, options);
        }
export type MyCertificatesQueryHookResult = ReturnType<typeof useMyCertificatesQuery>;
export type MyCertificatesLazyQueryHookResult = ReturnType<typeof useMyCertificatesLazyQuery>;
export type MyCertificatesQueryResult = Apollo.QueryResult<IMyCertificatesQuery, IMyCertificatesQueryVariables>;
export function refetchMyCertificatesQuery(variables?: IMyCertificatesQueryVariables) {
      return { query: MyCertificatesDocument, variables: variables }
    }
export const CreateCertificateByAdminDocument = gql`
    mutation CreateCertificateByAdmin($userId: Int!, $certificateDate: Date!) {
  createCertificateByAdmin(userId: $userId, certificateDate: $certificateDate)
}
    `;
export type ICreateCertificateByAdminMutationFn = Apollo.MutationFunction<ICreateCertificateByAdminMutation, ICreateCertificateByAdminMutationVariables>;

/**
 * __useCreateCertificateByAdminMutation__
 *
 * To run a mutation, you first call `useCreateCertificateByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCertificateByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCertificateByAdminMutation, { data, loading, error }] = useCreateCertificateByAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      certificateDate: // value for 'certificateDate'
 *   },
 * });
 */
export function useCreateCertificateByAdminMutation(baseOptions?: Apollo.MutationHookOptions<ICreateCertificateByAdminMutation, ICreateCertificateByAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateCertificateByAdminMutation, ICreateCertificateByAdminMutationVariables>(CreateCertificateByAdminDocument, options);
      }
export type CreateCertificateByAdminMutationHookResult = ReturnType<typeof useCreateCertificateByAdminMutation>;
export type CreateCertificateByAdminMutationResult = Apollo.MutationResult<ICreateCertificateByAdminMutation>;
export type CreateCertificateByAdminMutationOptions = Apollo.BaseMutationOptions<ICreateCertificateByAdminMutation, ICreateCertificateByAdminMutationVariables>;
export const AnswerExamQuestionDocument = gql`
    mutation AnswerExamQuestion($examId: Int!, $examQuestionId: Int!, $answerId: Int!) {
  answerExamQuestion(
    examId: $examId
    examQuestionId: $examQuestionId
    answerId: $answerId
  )
}
    `;
export type IAnswerExamQuestionMutationFn = Apollo.MutationFunction<IAnswerExamQuestionMutation, IAnswerExamQuestionMutationVariables>;

/**
 * __useAnswerExamQuestionMutation__
 *
 * To run a mutation, you first call `useAnswerExamQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerExamQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerExamQuestionMutation, { data, loading, error }] = useAnswerExamQuestionMutation({
 *   variables: {
 *      examId: // value for 'examId'
 *      examQuestionId: // value for 'examQuestionId'
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useAnswerExamQuestionMutation(baseOptions?: Apollo.MutationHookOptions<IAnswerExamQuestionMutation, IAnswerExamQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAnswerExamQuestionMutation, IAnswerExamQuestionMutationVariables>(AnswerExamQuestionDocument, options);
      }
export type AnswerExamQuestionMutationHookResult = ReturnType<typeof useAnswerExamQuestionMutation>;
export type AnswerExamQuestionMutationResult = Apollo.MutationResult<IAnswerExamQuestionMutation>;
export type AnswerExamQuestionMutationOptions = Apollo.BaseMutationOptions<IAnswerExamQuestionMutation, IAnswerExamQuestionMutationVariables>;
export const ExamDocument = gql`
    query Exam($id: Int!) {
  exam(id: $id) {
    exam {
      ...ExamFragment
    }
    results {
      ...ExamResults
    }
  }
}
    ${ExamFragmentFragmentDoc}
${ExamResultsFragmentDoc}`;

/**
 * __useExamQuery__
 *
 * To run a query within a React component, call `useExamQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExamQuery(baseOptions: Apollo.QueryHookOptions<IExamQuery, IExamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IExamQuery, IExamQueryVariables>(ExamDocument, options);
      }
export function useExamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IExamQuery, IExamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IExamQuery, IExamQueryVariables>(ExamDocument, options);
        }
export type ExamQueryHookResult = ReturnType<typeof useExamQuery>;
export type ExamLazyQueryHookResult = ReturnType<typeof useExamLazyQuery>;
export type ExamQueryResult = Apollo.QueryResult<IExamQuery, IExamQueryVariables>;
export function refetchExamQuery(variables: IExamQueryVariables) {
      return { query: ExamDocument, variables: variables }
    }
export const ExamsDocument = gql`
    query Exams($filter: ExamsFilter, $pagination: PaginationInput) {
  exams(filter: $filter, pagination: $pagination) {
    total
    list {
      ...ExamFragment
    }
  }
}
    ${ExamFragmentFragmentDoc}`;

/**
 * __useExamsQuery__
 *
 * To run a query within a React component, call `useExamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useExamsQuery(baseOptions?: Apollo.QueryHookOptions<IExamsQuery, IExamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IExamsQuery, IExamsQueryVariables>(ExamsDocument, options);
      }
export function useExamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IExamsQuery, IExamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IExamsQuery, IExamsQueryVariables>(ExamsDocument, options);
        }
export type ExamsQueryHookResult = ReturnType<typeof useExamsQuery>;
export type ExamsLazyQueryHookResult = ReturnType<typeof useExamsLazyQuery>;
export type ExamsQueryResult = Apollo.QueryResult<IExamsQuery, IExamsQueryVariables>;
export function refetchExamsQuery(variables?: IExamsQueryVariables) {
      return { query: ExamsDocument, variables: variables }
    }
export const ExamsForCalendarDocument = gql`
    query ExamsForCalendar($period: PeriodInput!) {
  examsForCalendar(period: $period) {
    total
    list {
      ...ExamFragment
    }
  }
}
    ${ExamFragmentFragmentDoc}`;

/**
 * __useExamsForCalendarQuery__
 *
 * To run a query within a React component, call `useExamsForCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamsForCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamsForCalendarQuery({
 *   variables: {
 *      period: // value for 'period'
 *   },
 * });
 */
export function useExamsForCalendarQuery(baseOptions: Apollo.QueryHookOptions<IExamsForCalendarQuery, IExamsForCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IExamsForCalendarQuery, IExamsForCalendarQueryVariables>(ExamsForCalendarDocument, options);
      }
export function useExamsForCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IExamsForCalendarQuery, IExamsForCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IExamsForCalendarQuery, IExamsForCalendarQueryVariables>(ExamsForCalendarDocument, options);
        }
export type ExamsForCalendarQueryHookResult = ReturnType<typeof useExamsForCalendarQuery>;
export type ExamsForCalendarLazyQueryHookResult = ReturnType<typeof useExamsForCalendarLazyQuery>;
export type ExamsForCalendarQueryResult = Apollo.QueryResult<IExamsForCalendarQuery, IExamsForCalendarQueryVariables>;
export function refetchExamsForCalendarQuery(variables: IExamsForCalendarQueryVariables) {
      return { query: ExamsForCalendarDocument, variables: variables }
    }
export const MyExamDocument = gql`
    query MyExam($myExamId: Int!) {
  myExam(id: $myExamId) {
    exam {
      ...ExamFragment
    }
    results {
      ...ExamResults
    }
  }
}
    ${ExamFragmentFragmentDoc}
${ExamResultsFragmentDoc}`;

/**
 * __useMyExamQuery__
 *
 * To run a query within a React component, call `useMyExamQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyExamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyExamQuery({
 *   variables: {
 *      myExamId: // value for 'myExamId'
 *   },
 * });
 */
export function useMyExamQuery(baseOptions: Apollo.QueryHookOptions<IMyExamQuery, IMyExamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMyExamQuery, IMyExamQueryVariables>(MyExamDocument, options);
      }
export function useMyExamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMyExamQuery, IMyExamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMyExamQuery, IMyExamQueryVariables>(MyExamDocument, options);
        }
export type MyExamQueryHookResult = ReturnType<typeof useMyExamQuery>;
export type MyExamLazyQueryHookResult = ReturnType<typeof useMyExamLazyQuery>;
export type MyExamQueryResult = Apollo.QueryResult<IMyExamQuery, IMyExamQueryVariables>;
export function refetchMyExamQuery(variables: IMyExamQueryVariables) {
      return { query: MyExamDocument, variables: variables }
    }
export const MyExamsDocument = gql`
    query MyExams($filter: ExamsFilter, $pagination: PaginationInput) {
  myExams(filter: $filter, pagination: $pagination) {
    total
    list {
      ...ExamFragment
    }
  }
}
    ${ExamFragmentFragmentDoc}`;

/**
 * __useMyExamsQuery__
 *
 * To run a query within a React component, call `useMyExamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyExamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyExamsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useMyExamsQuery(baseOptions?: Apollo.QueryHookOptions<IMyExamsQuery, IMyExamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMyExamsQuery, IMyExamsQueryVariables>(MyExamsDocument, options);
      }
export function useMyExamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMyExamsQuery, IMyExamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMyExamsQuery, IMyExamsQueryVariables>(MyExamsDocument, options);
        }
export type MyExamsQueryHookResult = ReturnType<typeof useMyExamsQuery>;
export type MyExamsLazyQueryHookResult = ReturnType<typeof useMyExamsLazyQuery>;
export type MyExamsQueryResult = Apollo.QueryResult<IMyExamsQuery, IMyExamsQueryVariables>;
export function refetchMyExamsQuery(variables?: IMyExamsQueryVariables) {
      return { query: MyExamsDocument, variables: variables }
    }
export const CreateExamRequestDocument = gql`
    mutation CreateExamRequest($input: CreateExamRequestInput!) {
  createExamRequest(input: $input) {
    ...ExamFragment
  }
}
    ${ExamFragmentFragmentDoc}`;
export type ICreateExamRequestMutationFn = Apollo.MutationFunction<ICreateExamRequestMutation, ICreateExamRequestMutationVariables>;

/**
 * __useCreateExamRequestMutation__
 *
 * To run a mutation, you first call `useCreateExamRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExamRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExamRequestMutation, { data, loading, error }] = useCreateExamRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExamRequestMutation(baseOptions?: Apollo.MutationHookOptions<ICreateExamRequestMutation, ICreateExamRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateExamRequestMutation, ICreateExamRequestMutationVariables>(CreateExamRequestDocument, options);
      }
export type CreateExamRequestMutationHookResult = ReturnType<typeof useCreateExamRequestMutation>;
export type CreateExamRequestMutationResult = Apollo.MutationResult<ICreateExamRequestMutation>;
export type CreateExamRequestMutationOptions = Apollo.BaseMutationOptions<ICreateExamRequestMutation, ICreateExamRequestMutationVariables>;
export const AssignExamDocument = gql`
    mutation AssignExam($id: Int!, $date: Date!) {
  assignExam(id: $id, date: $date)
}
    `;
export type IAssignExamMutationFn = Apollo.MutationFunction<IAssignExamMutation, IAssignExamMutationVariables>;

/**
 * __useAssignExamMutation__
 *
 * To run a mutation, you first call `useAssignExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignExamMutation, { data, loading, error }] = useAssignExamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useAssignExamMutation(baseOptions?: Apollo.MutationHookOptions<IAssignExamMutation, IAssignExamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAssignExamMutation, IAssignExamMutationVariables>(AssignExamDocument, options);
      }
export type AssignExamMutationHookResult = ReturnType<typeof useAssignExamMutation>;
export type AssignExamMutationResult = Apollo.MutationResult<IAssignExamMutation>;
export type AssignExamMutationOptions = Apollo.BaseMutationOptions<IAssignExamMutation, IAssignExamMutationVariables>;
export const StartExamDocument = gql`
    mutation StartExam($startExamId: Int!) {
  startExam(id: $startExamId) {
    ...ExamFragment
  }
}
    ${ExamFragmentFragmentDoc}`;
export type IStartExamMutationFn = Apollo.MutationFunction<IStartExamMutation, IStartExamMutationVariables>;

/**
 * __useStartExamMutation__
 *
 * To run a mutation, you first call `useStartExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startExamMutation, { data, loading, error }] = useStartExamMutation({
 *   variables: {
 *      startExamId: // value for 'startExamId'
 *   },
 * });
 */
export function useStartExamMutation(baseOptions?: Apollo.MutationHookOptions<IStartExamMutation, IStartExamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IStartExamMutation, IStartExamMutationVariables>(StartExamDocument, options);
      }
export type StartExamMutationHookResult = ReturnType<typeof useStartExamMutation>;
export type StartExamMutationResult = Apollo.MutationResult<IStartExamMutation>;
export type StartExamMutationOptions = Apollo.BaseMutationOptions<IStartExamMutation, IStartExamMutationVariables>;
export const CancelExamDocument = gql`
    mutation CancelExam($id: Int!) {
  cancelExam(id: $id)
}
    `;
export type ICancelExamMutationFn = Apollo.MutationFunction<ICancelExamMutation, ICancelExamMutationVariables>;

/**
 * __useCancelExamMutation__
 *
 * To run a mutation, you first call `useCancelExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelExamMutation, { data, loading, error }] = useCancelExamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelExamMutation(baseOptions?: Apollo.MutationHookOptions<ICancelExamMutation, ICancelExamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICancelExamMutation, ICancelExamMutationVariables>(CancelExamDocument, options);
      }
export type CancelExamMutationHookResult = ReturnType<typeof useCancelExamMutation>;
export type CancelExamMutationResult = Apollo.MutationResult<ICancelExamMutation>;
export type CancelExamMutationOptions = Apollo.BaseMutationOptions<ICancelExamMutation, ICancelExamMutationVariables>;
export const SingleFileUploadDocument = gql`
    mutation SingleFileUpload($file: Upload!, $type: FileType!) {
  singleFileUpload(file: $file, type: $type) {
    ...FileFragment
  }
}
    ${FileFragmentFragmentDoc}`;
export type ISingleFileUploadMutationFn = Apollo.MutationFunction<ISingleFileUploadMutation, ISingleFileUploadMutationVariables>;

/**
 * __useSingleFileUploadMutation__
 *
 * To run a mutation, you first call `useSingleFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSingleFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [singleFileUploadMutation, { data, loading, error }] = useSingleFileUploadMutation({
 *   variables: {
 *      file: // value for 'file'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSingleFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<ISingleFileUploadMutation, ISingleFileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISingleFileUploadMutation, ISingleFileUploadMutationVariables>(SingleFileUploadDocument, options);
      }
export type SingleFileUploadMutationHookResult = ReturnType<typeof useSingleFileUploadMutation>;
export type SingleFileUploadMutationResult = Apollo.MutationResult<ISingleFileUploadMutation>;
export type SingleFileUploadMutationOptions = Apollo.BaseMutationOptions<ISingleFileUploadMutation, ISingleFileUploadMutationVariables>;
export const LessonDocument = gql`
    query Lesson($id: Int!) {
  lesson(id: $id) {
    ...LessonFragment
  }
}
    ${LessonFragmentFragmentDoc}`;

/**
 * __useLessonQuery__
 *
 * To run a query within a React component, call `useLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLessonQuery(baseOptions: Apollo.QueryHookOptions<ILessonQuery, ILessonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ILessonQuery, ILessonQueryVariables>(LessonDocument, options);
      }
export function useLessonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ILessonQuery, ILessonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ILessonQuery, ILessonQueryVariables>(LessonDocument, options);
        }
export type LessonQueryHookResult = ReturnType<typeof useLessonQuery>;
export type LessonLazyQueryHookResult = ReturnType<typeof useLessonLazyQuery>;
export type LessonQueryResult = Apollo.QueryResult<ILessonQuery, ILessonQueryVariables>;
export function refetchLessonQuery(variables: ILessonQueryVariables) {
      return { query: LessonDocument, variables: variables }
    }
export const LessonsDocument = gql`
    query Lessons($filter: LessonsFilter, $pagination: PaginationInput) {
  lessons(filter: $filter, pagination: $pagination) {
    total
    list {
      ...LessonFragment
    }
  }
}
    ${LessonFragmentFragmentDoc}`;

/**
 * __useLessonsQuery__
 *
 * To run a query within a React component, call `useLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useLessonsQuery(baseOptions?: Apollo.QueryHookOptions<ILessonsQuery, ILessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ILessonsQuery, ILessonsQueryVariables>(LessonsDocument, options);
      }
export function useLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ILessonsQuery, ILessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ILessonsQuery, ILessonsQueryVariables>(LessonsDocument, options);
        }
export type LessonsQueryHookResult = ReturnType<typeof useLessonsQuery>;
export type LessonsLazyQueryHookResult = ReturnType<typeof useLessonsLazyQuery>;
export type LessonsQueryResult = Apollo.QueryResult<ILessonsQuery, ILessonsQueryVariables>;
export function refetchLessonsQuery(variables?: ILessonsQueryVariables) {
      return { query: LessonsDocument, variables: variables }
    }
export const UpsertLessonDocument = gql`
    mutation UpsertLesson($input: LessonInput) {
  upsertLesson(input: $input) {
    ...LessonFragment
  }
}
    ${LessonFragmentFragmentDoc}`;
export type IUpsertLessonMutationFn = Apollo.MutationFunction<IUpsertLessonMutation, IUpsertLessonMutationVariables>;

/**
 * __useUpsertLessonMutation__
 *
 * To run a mutation, you first call `useUpsertLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLessonMutation, { data, loading, error }] = useUpsertLessonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLessonMutation(baseOptions?: Apollo.MutationHookOptions<IUpsertLessonMutation, IUpsertLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpsertLessonMutation, IUpsertLessonMutationVariables>(UpsertLessonDocument, options);
      }
export type UpsertLessonMutationHookResult = ReturnType<typeof useUpsertLessonMutation>;
export type UpsertLessonMutationResult = Apollo.MutationResult<IUpsertLessonMutation>;
export type UpsertLessonMutationOptions = Apollo.BaseMutationOptions<IUpsertLessonMutation, IUpsertLessonMutationVariables>;
export const DeleteLessonDocument = gql`
    mutation DeleteLesson($id: Int!) {
  deleteLesson(id: $id)
}
    `;
export type IDeleteLessonMutationFn = Apollo.MutationFunction<IDeleteLessonMutation, IDeleteLessonMutationVariables>;

/**
 * __useDeleteLessonMutation__
 *
 * To run a mutation, you first call `useDeleteLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLessonMutation, { data, loading, error }] = useDeleteLessonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLessonMutation(baseOptions?: Apollo.MutationHookOptions<IDeleteLessonMutation, IDeleteLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IDeleteLessonMutation, IDeleteLessonMutationVariables>(DeleteLessonDocument, options);
      }
export type DeleteLessonMutationHookResult = ReturnType<typeof useDeleteLessonMutation>;
export type DeleteLessonMutationResult = Apollo.MutationResult<IDeleteLessonMutation>;
export type DeleteLessonMutationOptions = Apollo.BaseMutationOptions<IDeleteLessonMutation, IDeleteLessonMutationVariables>;
export const QuestionDocument = gql`
    query Question($questionId: Int!) {
  question(id: $questionId) {
    ...QuestionFragment
  }
}
    ${QuestionFragmentFragmentDoc}`;

/**
 * __useQuestionQuery__
 *
 * To run a query within a React component, call `useQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionQuery({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useQuestionQuery(baseOptions: Apollo.QueryHookOptions<IQuestionQuery, IQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IQuestionQuery, IQuestionQueryVariables>(QuestionDocument, options);
      }
export function useQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IQuestionQuery, IQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IQuestionQuery, IQuestionQueryVariables>(QuestionDocument, options);
        }
export type QuestionQueryHookResult = ReturnType<typeof useQuestionQuery>;
export type QuestionLazyQueryHookResult = ReturnType<typeof useQuestionLazyQuery>;
export type QuestionQueryResult = Apollo.QueryResult<IQuestionQuery, IQuestionQueryVariables>;
export function refetchQuestionQuery(variables: IQuestionQueryVariables) {
      return { query: QuestionDocument, variables: variables }
    }
export const QuestionsDocument = gql`
    query Questions($filter: QuestionsFilter, $pagination: PaginationInput) {
  questions(filter: $filter, pagination: $pagination) {
    total
    list {
      ...QuestionFragment
    }
  }
}
    ${QuestionFragmentFragmentDoc}`;

/**
 * __useQuestionsQuery__
 *
 * To run a query within a React component, call `useQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<IQuestionsQuery, IQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IQuestionsQuery, IQuestionsQueryVariables>(QuestionsDocument, options);
      }
export function useQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IQuestionsQuery, IQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IQuestionsQuery, IQuestionsQueryVariables>(QuestionsDocument, options);
        }
export type QuestionsQueryHookResult = ReturnType<typeof useQuestionsQuery>;
export type QuestionsLazyQueryHookResult = ReturnType<typeof useQuestionsLazyQuery>;
export type QuestionsQueryResult = Apollo.QueryResult<IQuestionsQuery, IQuestionsQueryVariables>;
export function refetchQuestionsQuery(variables?: IQuestionsQueryVariables) {
      return { query: QuestionsDocument, variables: variables }
    }
export const CreateQuestionDocument = gql`
    mutation CreateQuestion($input: QuestionInput!) {
  createQuestion(input: $input) {
    ...QuestionFragment
  }
}
    ${QuestionFragmentFragmentDoc}`;
export type ICreateQuestionMutationFn = Apollo.MutationFunction<ICreateQuestionMutation, ICreateQuestionMutationVariables>;

/**
 * __useCreateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionMutation, { data, loading, error }] = useCreateQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<ICreateQuestionMutation, ICreateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateQuestionMutation, ICreateQuestionMutationVariables>(CreateQuestionDocument, options);
      }
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>;
export type CreateQuestionMutationResult = Apollo.MutationResult<ICreateQuestionMutation>;
export type CreateQuestionMutationOptions = Apollo.BaseMutationOptions<ICreateQuestionMutation, ICreateQuestionMutationVariables>;
export const UpdateQuestionDocument = gql`
    mutation UpdateQuestion($input: QuestionInput!) {
  updateQuestion(input: $input) {
    ...QuestionFragment
  }
}
    ${QuestionFragmentFragmentDoc}`;
export type IUpdateQuestionMutationFn = Apollo.MutationFunction<IUpdateQuestionMutation, IUpdateQuestionMutationVariables>;

/**
 * __useUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionMutation, { data, loading, error }] = useUpdateQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateQuestionMutation, IUpdateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateQuestionMutation, IUpdateQuestionMutationVariables>(UpdateQuestionDocument, options);
      }
export type UpdateQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionMutation>;
export type UpdateQuestionMutationResult = Apollo.MutationResult<IUpdateQuestionMutation>;
export type UpdateQuestionMutationOptions = Apollo.BaseMutationOptions<IUpdateQuestionMutation, IUpdateQuestionMutationVariables>;
export const DeleteQuestionDocument = gql`
    mutation DeleteQuestion($deleteQuestionId: Int!) {
  deleteQuestion(id: $deleteQuestionId)
}
    `;
export type IDeleteQuestionMutationFn = Apollo.MutationFunction<IDeleteQuestionMutation, IDeleteQuestionMutationVariables>;

/**
 * __useDeleteQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionMutation, { data, loading, error }] = useDeleteQuestionMutation({
 *   variables: {
 *      deleteQuestionId: // value for 'deleteQuestionId'
 *   },
 * });
 */
export function useDeleteQuestionMutation(baseOptions?: Apollo.MutationHookOptions<IDeleteQuestionMutation, IDeleteQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IDeleteQuestionMutation, IDeleteQuestionMutationVariables>(DeleteQuestionDocument, options);
      }
export type DeleteQuestionMutationHookResult = ReturnType<typeof useDeleteQuestionMutation>;
export type DeleteQuestionMutationResult = Apollo.MutationResult<IDeleteQuestionMutation>;
export type DeleteQuestionMutationOptions = Apollo.BaseMutationOptions<IDeleteQuestionMutation, IDeleteQuestionMutationVariables>;
export const UserPaymentsDocument = gql`
    query UserPayments {
  userPayments {
    total
    list {
      ...UserPaymentFragment
    }
  }
}
    ${UserPaymentFragmentFragmentDoc}`;

/**
 * __useUserPaymentsQuery__
 *
 * To run a query within a React component, call `useUserPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPaymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<IUserPaymentsQuery, IUserPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IUserPaymentsQuery, IUserPaymentsQueryVariables>(UserPaymentsDocument, options);
      }
export function useUserPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IUserPaymentsQuery, IUserPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IUserPaymentsQuery, IUserPaymentsQueryVariables>(UserPaymentsDocument, options);
        }
export type UserPaymentsQueryHookResult = ReturnType<typeof useUserPaymentsQuery>;
export type UserPaymentsLazyQueryHookResult = ReturnType<typeof useUserPaymentsLazyQuery>;
export type UserPaymentsQueryResult = Apollo.QueryResult<IUserPaymentsQuery, IUserPaymentsQueryVariables>;
export function refetchUserPaymentsQuery(variables?: IUserPaymentsQueryVariables) {
      return { query: UserPaymentsDocument, variables: variables }
    }
export const UserPaymentsByAdminDocument = gql`
    query UserPaymentsByAdmin($userId: Int!) {
  userPaymentsByAdmin(userId: $userId) {
    total
    list {
      ...UserPaymentFragment
    }
  }
}
    ${UserPaymentFragmentFragmentDoc}`;

/**
 * __useUserPaymentsByAdminQuery__
 *
 * To run a query within a React component, call `useUserPaymentsByAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPaymentsByAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPaymentsByAdminQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserPaymentsByAdminQuery(baseOptions: Apollo.QueryHookOptions<IUserPaymentsByAdminQuery, IUserPaymentsByAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IUserPaymentsByAdminQuery, IUserPaymentsByAdminQueryVariables>(UserPaymentsByAdminDocument, options);
      }
export function useUserPaymentsByAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IUserPaymentsByAdminQuery, IUserPaymentsByAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IUserPaymentsByAdminQuery, IUserPaymentsByAdminQueryVariables>(UserPaymentsByAdminDocument, options);
        }
export type UserPaymentsByAdminQueryHookResult = ReturnType<typeof useUserPaymentsByAdminQuery>;
export type UserPaymentsByAdminLazyQueryHookResult = ReturnType<typeof useUserPaymentsByAdminLazyQuery>;
export type UserPaymentsByAdminQueryResult = Apollo.QueryResult<IUserPaymentsByAdminQuery, IUserPaymentsByAdminQueryVariables>;
export function refetchUserPaymentsByAdminQuery(variables: IUserPaymentsByAdminQueryVariables) {
      return { query: UserPaymentsByAdminDocument, variables: variables }
    }
export const CreateCashPaymentDocument = gql`
    mutation CreateCashPayment($file: Upload!, $type: FileType!) {
  createCashPayment(file: $file, type: $type) {
    ...UserPaymentFragment
  }
}
    ${UserPaymentFragmentFragmentDoc}`;
export type ICreateCashPaymentMutationFn = Apollo.MutationFunction<ICreateCashPaymentMutation, ICreateCashPaymentMutationVariables>;

/**
 * __useCreateCashPaymentMutation__
 *
 * To run a mutation, you first call `useCreateCashPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCashPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCashPaymentMutation, { data, loading, error }] = useCreateCashPaymentMutation({
 *   variables: {
 *      file: // value for 'file'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateCashPaymentMutation(baseOptions?: Apollo.MutationHookOptions<ICreateCashPaymentMutation, ICreateCashPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateCashPaymentMutation, ICreateCashPaymentMutationVariables>(CreateCashPaymentDocument, options);
      }
export type CreateCashPaymentMutationHookResult = ReturnType<typeof useCreateCashPaymentMutation>;
export type CreateCashPaymentMutationResult = Apollo.MutationResult<ICreateCashPaymentMutation>;
export type CreateCashPaymentMutationOptions = Apollo.BaseMutationOptions<ICreateCashPaymentMutation, ICreateCashPaymentMutationVariables>;
export const ResolveCashPaymentDocument = gql`
    mutation ResolveCashPayment($paymentId: Int!, $status: PaymentStatus!) {
  resolveCashPayment(paymentId: $paymentId, status: $status)
}
    `;
export type IResolveCashPaymentMutationFn = Apollo.MutationFunction<IResolveCashPaymentMutation, IResolveCashPaymentMutationVariables>;

/**
 * __useResolveCashPaymentMutation__
 *
 * To run a mutation, you first call `useResolveCashPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveCashPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveCashPaymentMutation, { data, loading, error }] = useResolveCashPaymentMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useResolveCashPaymentMutation(baseOptions?: Apollo.MutationHookOptions<IResolveCashPaymentMutation, IResolveCashPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IResolveCashPaymentMutation, IResolveCashPaymentMutationVariables>(ResolveCashPaymentDocument, options);
      }
export type ResolveCashPaymentMutationHookResult = ReturnType<typeof useResolveCashPaymentMutation>;
export type ResolveCashPaymentMutationResult = Apollo.MutationResult<IResolveCashPaymentMutation>;
export type ResolveCashPaymentMutationOptions = Apollo.BaseMutationOptions<IResolveCashPaymentMutation, IResolveCashPaymentMutationVariables>;
export const CreateCardPaymentDocument = gql`
    mutation CreateCardPayment {
  createCardPayment {
    payment {
      ...UserPaymentFragment
    }
    paymentUrl
  }
}
    ${UserPaymentFragmentFragmentDoc}`;
export type ICreateCardPaymentMutationFn = Apollo.MutationFunction<ICreateCardPaymentMutation, ICreateCardPaymentMutationVariables>;

/**
 * __useCreateCardPaymentMutation__
 *
 * To run a mutation, you first call `useCreateCardPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardPaymentMutation, { data, loading, error }] = useCreateCardPaymentMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateCardPaymentMutation(baseOptions?: Apollo.MutationHookOptions<ICreateCardPaymentMutation, ICreateCardPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateCardPaymentMutation, ICreateCardPaymentMutationVariables>(CreateCardPaymentDocument, options);
      }
export type CreateCardPaymentMutationHookResult = ReturnType<typeof useCreateCardPaymentMutation>;
export type CreateCardPaymentMutationResult = Apollo.MutationResult<ICreateCardPaymentMutation>;
export type CreateCardPaymentMutationOptions = Apollo.BaseMutationOptions<ICreateCardPaymentMutation, ICreateCardPaymentMutationVariables>;
export const PayCardPaymentDocument = gql`
    mutation PayCardPayment($paymentId: Int!) {
  payCardPayment(paymentId: $paymentId) {
    payment {
      ...UserPaymentFragment
    }
    paymentUrl
  }
}
    ${UserPaymentFragmentFragmentDoc}`;
export type IPayCardPaymentMutationFn = Apollo.MutationFunction<IPayCardPaymentMutation, IPayCardPaymentMutationVariables>;

/**
 * __usePayCardPaymentMutation__
 *
 * To run a mutation, you first call `usePayCardPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayCardPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payCardPaymentMutation, { data, loading, error }] = usePayCardPaymentMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function usePayCardPaymentMutation(baseOptions?: Apollo.MutationHookOptions<IPayCardPaymentMutation, IPayCardPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IPayCardPaymentMutation, IPayCardPaymentMutationVariables>(PayCardPaymentDocument, options);
      }
export type PayCardPaymentMutationHookResult = ReturnType<typeof usePayCardPaymentMutation>;
export type PayCardPaymentMutationResult = Apollo.MutationResult<IPayCardPaymentMutation>;
export type PayCardPaymentMutationOptions = Apollo.BaseMutationOptions<IPayCardPaymentMutation, IPayCardPaymentMutationVariables>;
export const UsersDocument = gql`
    query Users($filter: UsersFilter, $pagination: PaginationInput) {
  users(filter: $filter, pagination: $pagination) {
    total
    list {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<IUsersQuery, IUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IUsersQuery, IUsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IUsersQuery, IUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IUsersQuery, IUsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<IUsersQuery, IUsersQueryVariables>;
export function refetchUsersQuery(variables?: IUsersQueryVariables) {
      return { query: UsersDocument, variables: variables }
    }
export const UserByAdminDocument = gql`
    query UserByAdmin($id: Int!) {
  userByAdmin(id: $id) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useUserByAdminQuery__
 *
 * To run a query within a React component, call `useUserByAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByAdminQuery(baseOptions: Apollo.QueryHookOptions<IUserByAdminQuery, IUserByAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IUserByAdminQuery, IUserByAdminQueryVariables>(UserByAdminDocument, options);
      }
export function useUserByAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IUserByAdminQuery, IUserByAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IUserByAdminQuery, IUserByAdminQueryVariables>(UserByAdminDocument, options);
        }
export type UserByAdminQueryHookResult = ReturnType<typeof useUserByAdminQuery>;
export type UserByAdminLazyQueryHookResult = ReturnType<typeof useUserByAdminLazyQuery>;
export type UserByAdminQueryResult = Apollo.QueryResult<IUserByAdminQuery, IUserByAdminQueryVariables>;
export function refetchUserByAdminQuery(variables: IUserByAdminQueryVariables) {
      return { query: UserByAdminDocument, variables: variables }
    }
export const UpdateProfileDataDocument = gql`
    mutation UpdateProfileData($input: UpdateProfileDataInput!) {
  updateProfileData(input: $input) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type IUpdateProfileDataMutationFn = Apollo.MutationFunction<IUpdateProfileDataMutation, IUpdateProfileDataMutationVariables>;

/**
 * __useUpdateProfileDataMutation__
 *
 * To run a mutation, you first call `useUpdateProfileDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileDataMutation, { data, loading, error }] = useUpdateProfileDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileDataMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateProfileDataMutation, IUpdateProfileDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateProfileDataMutation, IUpdateProfileDataMutationVariables>(UpdateProfileDataDocument, options);
      }
export type UpdateProfileDataMutationHookResult = ReturnType<typeof useUpdateProfileDataMutation>;
export type UpdateProfileDataMutationResult = Apollo.MutationResult<IUpdateProfileDataMutation>;
export type UpdateProfileDataMutationOptions = Apollo.BaseMutationOptions<IUpdateProfileDataMutation, IUpdateProfileDataMutationVariables>;
export const UpdateUserProfileByAdminDocument = gql`
    mutation UpdateUserProfileByAdmin($userId: Int!, $input: UpdateProfileDataInput!) {
  updateUserProfileByAdmin(userId: $userId, input: $input) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type IUpdateUserProfileByAdminMutationFn = Apollo.MutationFunction<IUpdateUserProfileByAdminMutation, IUpdateUserProfileByAdminMutationVariables>;

/**
 * __useUpdateUserProfileByAdminMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileByAdminMutation, { data, loading, error }] = useUpdateUserProfileByAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileByAdminMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateUserProfileByAdminMutation, IUpdateUserProfileByAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateUserProfileByAdminMutation, IUpdateUserProfileByAdminMutationVariables>(UpdateUserProfileByAdminDocument, options);
      }
export type UpdateUserProfileByAdminMutationHookResult = ReturnType<typeof useUpdateUserProfileByAdminMutation>;
export type UpdateUserProfileByAdminMutationResult = Apollo.MutationResult<IUpdateUserProfileByAdminMutation>;
export type UpdateUserProfileByAdminMutationOptions = Apollo.BaseMutationOptions<IUpdateUserProfileByAdminMutation, IUpdateUserProfileByAdminMutationVariables>;
export const UpdateUserDataDocument = gql`
    mutation UpdateUserData($input: UpdateUserDataInput!) {
  updateUserData(input: $input) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type IUpdateUserDataMutationFn = Apollo.MutationFunction<IUpdateUserDataMutation, IUpdateUserDataMutationVariables>;

/**
 * __useUpdateUserDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDataMutation, { data, loading, error }] = useUpdateUserDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserDataMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateUserDataMutation, IUpdateUserDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateUserDataMutation, IUpdateUserDataMutationVariables>(UpdateUserDataDocument, options);
      }
export type UpdateUserDataMutationHookResult = ReturnType<typeof useUpdateUserDataMutation>;
export type UpdateUserDataMutationResult = Apollo.MutationResult<IUpdateUserDataMutation>;
export type UpdateUserDataMutationOptions = Apollo.BaseMutationOptions<IUpdateUserDataMutation, IUpdateUserDataMutationVariables>;
export const UpdateUserDataByAdminDocument = gql`
    mutation UpdateUserDataByAdmin($userId: Int!, $input: UpdateUserDataByAdminInput!) {
  updateUserDataByAdmin(userId: $userId, input: $input) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type IUpdateUserDataByAdminMutationFn = Apollo.MutationFunction<IUpdateUserDataByAdminMutation, IUpdateUserDataByAdminMutationVariables>;

/**
 * __useUpdateUserDataByAdminMutation__
 *
 * To run a mutation, you first call `useUpdateUserDataByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDataByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDataByAdminMutation, { data, loading, error }] = useUpdateUserDataByAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserDataByAdminMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateUserDataByAdminMutation, IUpdateUserDataByAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateUserDataByAdminMutation, IUpdateUserDataByAdminMutationVariables>(UpdateUserDataByAdminDocument, options);
      }
export type UpdateUserDataByAdminMutationHookResult = ReturnType<typeof useUpdateUserDataByAdminMutation>;
export type UpdateUserDataByAdminMutationResult = Apollo.MutationResult<IUpdateUserDataByAdminMutation>;
export type UpdateUserDataByAdminMutationOptions = Apollo.BaseMutationOptions<IUpdateUserDataByAdminMutation, IUpdateUserDataByAdminMutationVariables>;
export const UpdateAdminDataDocument = gql`
    mutation UpdateAdminData($input: UpdateAdminDataInput!) {
  updateAdminData(input: $input) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type IUpdateAdminDataMutationFn = Apollo.MutationFunction<IUpdateAdminDataMutation, IUpdateAdminDataMutationVariables>;

/**
 * __useUpdateAdminDataMutation__
 *
 * To run a mutation, you first call `useUpdateAdminDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminDataMutation, { data, loading, error }] = useUpdateAdminDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdminDataMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateAdminDataMutation, IUpdateAdminDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateAdminDataMutation, IUpdateAdminDataMutationVariables>(UpdateAdminDataDocument, options);
      }
export type UpdateAdminDataMutationHookResult = ReturnType<typeof useUpdateAdminDataMutation>;
export type UpdateAdminDataMutationResult = Apollo.MutationResult<IUpdateAdminDataMutation>;
export type UpdateAdminDataMutationOptions = Apollo.BaseMutationOptions<IUpdateAdminDataMutation, IUpdateAdminDataMutationVariables>;
export const ExportUsersCsvDocument = gql`
    mutation ExportUsersCsv($filter: UsersFilter) {
  exportUsersCsv(filter: $filter)
}
    `;
export type IExportUsersCsvMutationFn = Apollo.MutationFunction<IExportUsersCsvMutation, IExportUsersCsvMutationVariables>;

/**
 * __useExportUsersCsvMutation__
 *
 * To run a mutation, you first call `useExportUsersCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUsersCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUsersCsvMutation, { data, loading, error }] = useExportUsersCsvMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportUsersCsvMutation(baseOptions?: Apollo.MutationHookOptions<IExportUsersCsvMutation, IExportUsersCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IExportUsersCsvMutation, IExportUsersCsvMutationVariables>(ExportUsersCsvDocument, options);
      }
export type ExportUsersCsvMutationHookResult = ReturnType<typeof useExportUsersCsvMutation>;
export type ExportUsersCsvMutationResult = Apollo.MutationResult<IExportUsersCsvMutation>;
export type ExportUsersCsvMutationOptions = Apollo.BaseMutationOptions<IExportUsersCsvMutation, IExportUsersCsvMutationVariables>;